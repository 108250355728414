import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import for_student from './routes/for_student'
import students from './routes/students'
import requests from './routes/requests'
import control from './routes/control'
import chat from './routes/chat'
import ticket from './routes/ticket'
import emtyaz from './routes/emtyaz'
import transfer from './routes/transfer'
import payments from './routes/payments'
import quality from './routes/quality'
import training from './routes/training'
import grades_recheck from './routes/grades_recheck'
import hotels from './routes/hotels'
import admission from './routes/admission'

const auth = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...dashboard,
    ...pages,
    ...for_student,
    ...students,
    ...requests,
    ...control,
    ...chat,
    ...ticket,
    ...emtyaz,
    ...transfer,
    ...payments,
    ...quality,
    ...training,
    ...grades_recheck,
    ...hotels,
    ...admission,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.meta && to.meta.admission) {
    return next()
  }
  if (to.query.code) {
    return next({
      name: 'auth-login',
      params: {
        code: to.query.code,
        session_state: to.query.session_state,
        state: to.query.state,
      },
    })
  }
  if (!isLoggedIn && !to.meta.redirectIfLoggedIn && to.name !== 'misc-under-maintenance') {
    return next({ name: 'auth-login' })
  }
  if (!canNavigate(to) && to.name !== 'misc-under-maintenance') {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(to => {
  const isLoggedIn = isUserLoggedIn()
  if (to.name == 'auth-login' && isLoggedIn) {
    router.push({ name: 'dashboard' })
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
